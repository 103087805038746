<template>
  <base-section
    id="over-20-years-of-experience"
  >
    <base-section-heading
      title="over 20 years of experience"
    >
      With over 20 years of combined experience and expertise in engineering design, process technology and manufacturing of nitrile glove products, we are committed to providing a one-stop nitrile manufacturing solution to the industry
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(info, n) in information"
          :key="n"
          cols="12"
          md="4"
          sm="12"
        >
          <v-hover>
            <template #default="{ hover }">
              <base-img
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                :src="info.src"
                color="red"
                flat
                tile
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-4"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading
                    :title="info.title"
                    class="text-center"
                  />

                  <base-body
                    class="text-center"
                    v-html="info.text"
                  >
                    {{info.text}}
                  </base-body>
                </v-overlay>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionOver20YearsOfExperience',
    data () {
      return {
        information: [
          {
            title: 'ENGINEERING DESIGN',
            text: '- Nitrile glove production lines',
            src: require('@/assets/experience-1.jpeg'),
          },
          {
            title: 'PROCESS TECHNOLOGY',
            text: '- Medical exam gloves</br>- Food contact gloves</br>- Accelerator-free gloves',
            src: require('@/assets/experience-2.jpeg'),
          },
          {
            title: 'MANUFACTURING TECHNOLOGY',
            text: '- Automation process<br>- Improvements & innovations',
            src: require('@/assets/experience-3.jpeg'),
          },
        ],
      }
    },
  }
</script>
